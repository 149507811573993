import { useAbout } from 'hooks'
import { FC } from 'react'

const About: FC = () => {
  const about = useAbout()

  return (
    <>
      <div
        className={`fancyLinks`}
        dangerouslySetInnerHTML={{ __html: about }}
      />
    </>
  )
}

export default About
