import 'styles/fancyLinks.css'

import SEO from '@bradgarropy/gatsby-plugin-seo'
import About from 'components/Pages/About'
import { FC } from 'react'

const AboutPage: FC = () => {
  return (
    <>
      <SEO
        title="About | Hacking the JS Interview"
        facebook={{
          image: 'https://hackingthejsinterview.com/logo.png'
        }}
        twitter={{
          image: 'https://hackingthejsinterview.com/logo.png',
          card: 'summary_large_image'
        }}
      />

      <About />
    </>
  )
}

export default AboutPage
